<template>
    <properties-block v-bind="$props" v-on="$listeners">
        <h4 v-tr>Spacer|Espacement</h4>

        <b-form-group label-cols="4" :label="'Size|Taille'|tr">
            <template #label>
                <span v-tr>Size|Taille</span>
                <btn-responsive-prop v-model="block" field="size"/>
            </template>
            <b-select v-model="block.mediaProxy.size">
                <option v-for="n in 100" :key="`width-${n}`" :value="`${n*5}px`">{{n*5}}px</option>
            </b-select>
        </b-form-group>
    </properties-block>
</template>

<script>
import PropertiesBlock from "@/components/editor/properties/blocks/properties-block.vue";

// properties-block-spacer #hot-reload-debug
export default {
    name: `properties-block-spacer`,
    components: {PropertiesBlock},
    extends: PropertiesBlock
}
</script>

<style lang="scss" scoped>
</style>
